#root{
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: Cairo;
  src: url('/public/fonts/arabic/Cairo-Regular.ttf');
}
@font-face {
  font-family: MyriadPro;
  src: url('/public/fonts/english/MYRIADPRO-REGULAR.OTF');
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.conter{
  width: 75%;
  margin: auto;
}
.image-header{
  width: 100%;
  background-repeat: no-repeat;
  background-size:cover;
  background-position: 50% 50%;
  border-radius: 0 0 130px 130px;
  overflow: hidden;
}
.image1{
  min-height: 437px;
}
.image2{
  height: 310.6px;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-Register{
  margin: 62px auto 0px auto;
  /* position: absolute; */
}
.logo-image{
  width: 161px;
  height: 80.8px;
}
.Register{
  color: white;
  border: 1px solid #00bce4;
  text-decoration: none;
  height: 36px;
  width: 300px;
  text-align: center;
  padding-top: 4px;
  border-radius: 6px;
}

.font-eng{
  font-family: MyriadPro;
  font-size: 18px;
  font-weight: bold;
  margin-right: 5px;
  color: white;
}
.font-arab{
  font-family: Cairo;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
  color: white;
}

.header-wrapper{
  display: flex;
  align-items: center;
  flex-direction: column ;
}

.title-h1{
  max-width: 1003px;
  font-size: 75px ;
  font-weight: bold;
  font-family: MyriadPro;
  color:#fff;
  text-align: center;
  margin: 0 auto 56px auto;
  /* line-height: 0.92; */
  letter-spacing: -0.61px;
}
.title-h2{
  max-width: 1003px;
  font-size: 75px;
  font-weight: bold;
  font-family: MyriadPro;
  color:#fff;
  text-align: center;
  margin: 0 auto 28px auto;
  line-height: 0.92;
  letter-spacing: -0.61px;
}
.read-more{
  font-size: 23px;
  font-family: MyriadPro;
  font-weight: bold;
  color: #00bce4;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
.read-img{
  width: 31px;
  height: 14px;
  margin: 0 0 5px 10px
}
.form-info{
  background-color: #fff;
  margin: auto;
  border-radius:0 0 130px 130px;
  padding-bottom: 78px;
}
.sign-in{
  width: 60%;
  margin: auto;
}
.sign{
  color: #175eac;
  font-size: 32px;
  font-weight: bold;
  font-family: MyriadPro;
  margin-top: 47px;
  float: left;
  line-height: 1.16;
  letter-spacing: -0.26px;
  text-transform: capitalize;
}
.sign1{
  color: #175eac;
  font-size: 28px;
  font-weight: bold;
  font-family: Cairo;
  float: right;
  margin-top: 47px;
  line-height: 1.57;
  letter-spacing: -0.23px;
}
.clear{
  clear: both;
}
.form-mask{
  width: 60%;
  margin: auto;
  margin-top: 20px;
}
.form-text{
  width: 100%;
}
.form-left{
  font-size: 16px;
  font-family: MyriadPro;
  color: #5a5a5a;
  float: left;
  text-transform: capitalize;
}
.form-right{
  font-size: 15px;
  font-family: Cairo;
  color: #5a5a5a;
  float:right;
}
.form-forget{
  margin:22.5px 0;
}

.a-color1{
  color: #5a5a5a;
  font-family: MyriadPro;
  font-size: 18px;
  text-decoration: underline;
  /* cursor: pointer; */
}
.a-c1{
  font-weight: 600;
}
.a-color2{
  color: #5a5a5a;
  font-family: Cairo;
  font-size: 16px;
  text-decoration: underline;
}
.a-c2{
  color: #5a5a5a;
  font-family: Cairo;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.prim-btn{
  width: 100%;
  height: 66px;
  background-color: #00bce4;
  border-radius: 6px;
  border-width: 0;
  text-align: center;
  font-size: 18px;
  color: white;
}
.login-f-eng{
  font-family: Cairo;
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}
.login-f-arab{
  font-family: Cairo;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
}

.Register-btn{
  width: 40%;
  height: 66px;
  background-color: #00bce4;
  border-radius: 6px;
  border-width: 0;
  text-align: center;
  font-size: 18px;
  color: white;
}

input[type='checkbox'] { 
  max-width: 22px;
  height: 23px;
  border-radius: 6px;
  border: solid 1px #e6e8eb;
  margin-right: 8px;
}
.input-error{
  border-color: #ff0000  ;
}
.error-msg{
  color: #ff0000;
}
/* .welcome{
  margin-top: 13px;
}
.wel-back{
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  margin: 0 6px;
} */
.layout-img{
  width: 18.2px;
  height: 18.2px;
  margin-left: 9px;
  cursor: pointer;
}
.signup-overly{
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.overly-up{
  width: 695px;
  height: 849px;
  border-radius: 50px;
  background-color: #175eac;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.closeTerms{
  color: white;
  font-size: 25px;
}
.overly-up img{
  width: 325px;
  height: 266px;
  margin-top: 123px;
}
.overly-up .your-reg{
  font-size: 32px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: -0.26px;
  text-align: center;
  color: #fff;
  margin-top: 78px;
}
.overly-up .your-reg-arb{
  font-size: 28px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: -0.23px;
  text-align: center;
  color: #fff;
  margin-top: 28px;
}
.done-btn-div{
  width: 399px;
  height: 66px;
  background-color: #00bce4;
  margin: 59px auto 122px auto;
  border-radius: 6px;
  cursor: pointer;
}
.done-btn{
  display: inline-block;
  text-decoration: none;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: 60px;
  padding-top: 6px;
  color: #fff;
  border: none;
  text-align: center;
  text-transform: capitalize;
}
.done-btn:hover{
  color: white
}
.reco-d{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.reco-eng{
  font-size: 35px;
  font-weight: bold;
  color: #00bce4;
  margin-top: 30px;
  font-family: MyriadPro;
}
.reco-arab{
  font-size: 30px;
  font-weight: bold;
  color: #00bce4;
  margin-top: 30px;
  font-family: Cairo;
}
.reco-arab-2{
  font-size: 30px;
  font-weight: bold;
  color: #00bce4;
  margin-top: 30px;
  font-family: Cairo;
}
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.slogan-txt {
  font-family: MyriadPro;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.12px;
  color: #fff;
  text-decoration: none;
  margin-top: 5px;
}
a {
  text-decoration: none;
}
@media screen and (max-width:850px) {
  .conter{
    width: 80%;
  } 

  .header-wrapper{
    align-items: flex-start;
  }

  .title-h2{
    font-size: 45px;
    text-align: left;
    font-weight: bold;
    padding-left: 42px;
    margin: 0 0 28px;
    line-height: normal;
  }

  .read-more{
    padding-left: 42px;
  }
  .reco-arab-2{
    display: none;
  }
}
@media screen and (max-width:560px) {
  .reco-eng{
    font-size: 25px;
    font-weight: bold;
    color: #00bce4;
    margin-top: 30px;
    font-family: MyriadPro;
  }
  .reco-arab{
    text-align: right;
    font-size: 20px;
  }
  .reco-arab-2{
    display: none;
  }
  .Register-btn{
    font-size: 14px;
  }
}